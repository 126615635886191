class GalleryPlan {
    constructor(selector) {
        this.selector = selector;
        this.categories = this.selector.querySelector('[data-categories]');
        this.sidebar = this.selector.querySelector('[data-sidebar]');
        this.search_box = this.sidebar.querySelector('[data-search-box]');
        this.shop_list = this.sidebar.querySelector('[data-shop-list]');
        this.content = this.selector.querySelector('[data-map-content]');
        this.map_levels_switcher = this.content.querySelector('[data-map-level-switcher]');
        this.map = this.content.querySelector('[data-map]');
        this.mobile_shop_info = this.selector.querySelector('[data-mobile-shop-info]');
        this.popover = null;
        this.modal = null;
        this.current_map_level = 0;
        this.inOnMobile = false;

        if (window.innerWidth <= 1099) {
            this.isOnMobile = true;
        }

        this._checkUrl();
        this._prepareCategoriesList().then((data) => {
            if (data !== null) {
                data.forEach(item => {
                    let element = document.createElement('a');
                    element.setAttribute('href', '#');
                    element.classList.add('plan__categories-list-element');
                    element.setAttribute('title', item.cat_name);
                    element.setAttribute('data-item', '');
                    element.setAttribute('data-item-category-shops', item.shops);

                    let img = document.createElement('img');
                    img.setAttribute('alt', item.cat_name);
                    img.setAttribute('src', ((item.icon !== '') ? item.icon : '/wp-content/themes/galeria-debicka/dist/images/icons/icon-gastronomy.svg'));
                    element.appendChild(img);
                    this.categories.appendChild(element);

                    element.addEventListener('click', e => {
                        e.preventDefault();

                        let shops = element.getAttribute('data-item-category-shops');
                        let shops_array = shops.split(',');

                        if (element.classList.contains('plan__categories-list-element--active')) {
                            this._clearActiveShops();
                            element.classList.remove('plan__categories-list-element--active');
                            this._clearMarkPaths();
                        } else {
                            if (shops_array.length > 0) {
                                this._setActiveShopsForCategory(shops_array);
                                this._clearAllCategoriesActive();
                                this._clearMarkPaths();

                                let changeMapLevel = false;
                                shops_array.forEach(shop => {
                                    let local_premise = this.shop_list.querySelector('[data-item-id="' + shop + '"]');
                                    let local_level = local_premise.getAttribute('data-item-level');
                                    if (!changeMapLevel) {
                                        this._setCurrentMapLevel(local_level);
                                        changeMapLevel = true;
                                    }

                                    if (local_premise !== '') {
                                        let local_id = local_premise.getAttribute('data-item-premise') || '';
                                        let premises = local_id.split(',');
                                        premises.forEach(premise => {
                                            let path = this.map.querySelector('[data-name="' + premise + '"]');
                                            if (path !== null) {
                                                path.classList.add('active');
                                            }
                                        });
                                    }
                                });
                                if (this.isOnMobile) {
                                    window.scrollTo({
                                        top: this.map.getBoundingClientRect().top + window.pageYOffset - 300
                                    });
                                }
                                element.classList.add('plan__categories-list-element--active');
                            }
                        }
                    });
                });
            } else {
                let message = document.createElement('p');
                message.innerText = 'Lista kategorii jest pusta';
                this.categories.appendChild(message);
            }
        });
        this._prepareShopList().then((data) => {
            if (data !== null) {
                data.forEach(item => {

                    let element = document.createElement('a');
                    element.setAttribute('href', '#');
                    element.setAttribute('data-item-premise', item.premise);
                    element.setAttribute('data-item-id', item.id);
                    element.setAttribute('data-item-level', item.level);
                    element.setAttribute('data-name', item.title);
                    element.innerText = item.title;

                    this.shop_list.appendChild(element);

                    let premises = item.premise.split(',');
                    premises.forEach(premise => {
                        let path = this.map.querySelector('[data-name="' + premise + '"]');
                        if (path !== null) {
                            path.setAttribute('data-shop-id', item.id);
                            path.setAttribute('data-shop-name', item.title);
                        }
                    });

                    element.addEventListener('click', e => {
                        e.preventDefault();

                        let local_id = (element.getAttribute('data-item-premise') || '').split(',');
                        let shop_id = element.getAttribute('data-item-id') || '';
                        let level_id = (element.getAttribute('data-item-level') || '0').split(',');
                        if (local_id.length > 0) {
                            if (this.map !== null) {
                                this._clearMarkPaths();

                                local_id.forEach(local => {
                                    let path = this.map.querySelector('[data-name="' + local + '"]');
                                    if (path !== null) {
                                        if (this.isOnMobile) {
                                            this.mobile_shop_info.style.display = 'block';
                                            if (shop_id !== '') {
                                                this._getShopData(shop_id).then((data) => {
                                                    let shop_name = data.name,
                                                        logo_url = data.logo,
                                                        link = data.link,
                                                        cat_name = data.cat_name,
                                                        cat_link = data.cat_link;

                                                    let loader = this.mobile_shop_info.querySelector('[data-element="loader"]');
                                                    let content = '' +
                                                        '<img alt="' + shop_name + '" class="shop-logo" src="' + logo_url + '" />' +
                                                        '<a href="' + cat_link + '" class="shop-category">' + cat_name + '</a>' +
                                                        '<h4 class="shop-name">' + shop_name + '</h4>' +
                                                        '<a href="' + link + '" class="button button--link button--arrow button--arrow-blue">Zobacz więcej</a>' +
                                                        '</div>';
                                                    loader.style.opacity = 0;

                                                    if (this.mobile_shop_info !== null) {
                                                        let content_div = this.mobile_shop_info.querySelector('.content');

                                                        content_div.innerHTMl = '';
                                                        content_div.innerHTML = content;

                                                        window.scrollTo({
                                                            top: this.mobile_shop_info.getBoundingClientRect().top + window.pageYOffset - 300
                                                        });
                                                    }
                                                });
                                            }
                                        } else {
                                            window.scrollTo({
                                                top: this.map.getBoundingClientRect().top + window.pageYOffset - 300
                                            });
                                        }
                                        path.classList.add('active');
                                    }
                                });
                                // console.log(level_id, this.current_map_level);
                                if (level_id.length >= 1 && level_id[0] !== this.current_map_level) this._setCurrentMapLevel(level_id[0]);
                            }
                        }
                    });
                });

                // Wszystkie lokale bez przypisanego id uznawane są za wolne, narzucamy klasę z kolorem
                let locals = this.map.querySelectorAll('[data-name]');
                locals.forEach(loc => {
                    loc.classList.add('premise');
                    let s_id = loc.getAttribute('data-shop-id');
                    if (s_id == null) {
                        loc.classList.add('free-premise');
                    }
                });
            } else {
                let message = document.createElement('p');
                message.innerText = 'Lista sklepów jest pusta';
                this.shop_list.appendChild(message);
            }
        });
        let levels_switcher_buttons = this.map_levels_switcher.querySelectorAll('a');
        if (levels_switcher_buttons !== null) {
            levels_switcher_buttons.forEach(button => {
                button.addEventListener('click', e => {
                    e.preventDefault();

                    let lvl = button.getAttribute('data-map-level-to');
                    if (lvl !== undefined) {
                        this._setCurrentMapLevel(lvl);
                    }
                });
            });
        }

        let search_input = this.search_box.querySelector('[data-input]');
        if (search_input !== null) {
            search_input.addEventListener('input', () => {
                if (search_input.value !== '') {
                    this._showShopsBySearchPhrase(search_input.value);
                } else {
                    this._showAllShopsOnList();
                }
            });
        }

        if (this.map !== null) {
            let maps = this.map.querySelectorAll('[data-map-level]');
            if (maps !== null) {
                maps.forEach(map => {
                    let objects = map.querySelectorAll('[data-name]');
                    if (objects.length) {
                        objects.forEach(object => {
                            object.addEventListener('click', e => {
                                e.preventDefault();

                                this._clearMarkPaths();

                                if (object.classList.contains('active')) {
                                    object.classList.remove('active');
                                } else {
                                    if (this.modal) {
                                        this.modal.remove();
                                    }

                                    let shop_id = object.getAttribute('data-shop-id');

                                    if (shop_id !== null) {
                                        if (!this.isOnMobile) {
                                            let container = document.body;
                                            let container_bounds = container.getBoundingClientRect();
                                            // console.log(container, container_bounds);
                                            let bounds = object.getBoundingClientRect();
                                            // console.log(bounds);
                                            let modal = this._createElementFromHTML(this._createModal());

                                            let modal_width = 400;
                                            if ((modal_width + bounds.right) > container_bounds.width) {
                                                // console.log('Warunek 1');
                                                modal.style.left = `${bounds.left - modal_width}px`;
                                            } else {
                                                // console.log('Warunek - else');
                                                modal.style.left = `${bounds.left + 200}px`;
                                            }
                                            modal.style.top = `50%`;

                                            modal.querySelector('[data-element="close"]').addEventListener('click', e => {
                                                e.preventDefault();

                                                if (this.modal) {
                                                    this.modal.remove();
                                                }
                                            });

                                            this.modal = document.body.appendChild(modal);
                                        } else {
                                            this.mobile_shop_info.style.display = 'block';
                                        }

                                        this._getShopData(shop_id).then((data) => {
                                            let shop_name = data.name,
                                                logo_url = data.logo,
                                                link = data.link,
                                                cat_name = data.cat_name,
                                                cat_link = data.cat_link;

                                            object.classList.add('active');

                                            let loader = null;
                                            if (!this.isOnMobile) {
                                                loader = this.modal.querySelector('[data-element="loader"]');
                                            } else {
                                                loader = this.mobile_shop_info.querySelector('[data-element="loader"]');
                                            }
                                            let content = '' +
                                                '<img alt="' + shop_name + '" class="shop-logo" src="' + logo_url + '" />' +
                                                '<a href="' + cat_link + '" class="shop-category">' + cat_name + '</a>' +
                                                '<h4 class="shop-name">' + shop_name + '</h4>' +
                                                '<a href="' + link + '" class="button button--link button--arrow button--arrow-blue">Zobacz więcej</a>' +
                                                '</div>';
                                            loader.style.opacity = 0;
                                            if (!this.isOnMobile) {
                                                this.modal.querySelector('.modal__content').innerHTML = content;
                                            } else {
                                                if (this.mobile_shop_info !== null) {
                                                    let content_div = this.mobile_shop_info.querySelector('.content');

                                                    content_div.innerHTMl = '';
                                                    content_div.innerHTML = content;

                                                    window.scrollTo({
                                                        top: this.mobile_shop_info.getBoundingClientRect().top + window.pageYOffset - 300
                                                    });
                                                }
                                            }

                                        });
                                    }
                                }
                            });
                            object.addEventListener('mouseenter', e => {
                                let local_id = object.getAttribute('data-shop-name') || '';
                                if (local_id !== '') {
                                    let bounds = object.getBoundingClientRect();
                                    let coords = this._getCoords(object);
                                    let popover = this._createElementFromHTML(this._createPopover(local_id));
                                    popover.style.left = `${coords.left + (bounds.width / 2)}px`;
                                    popover.style.top = `${coords.top - 30 + (bounds.height / 2)}px`;
                                    popover.style.bottom = 'auto';
                                    popover.style.right = 'auto';
                                    this.popover = document.body.appendChild(popover);
                                }
                            });
                            object.addEventListener('mouseleave', e => {
                                if (this.popover) {
                                    this.popover.remove();
                                }
                            });
                        });
                    }
                });
            }
        }
    }
    _prepareCategoriesList() {
        let url = '/wp-json/rondo/v1/get_data_categories/';
        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _prepareShopList() {
        let url = '/wp-json/rondo/v1/get_data_shop_list/';
        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _checkUrl() {
        let url = new URLSearchParams(window.location.search);
        let location = url.get('location');
        // console.log(location);

        if (location !== null) {
            let shop_level = parseInt(location.split('-')[1])-1;
            this._setCurrentMapLevel(shop_level);
            if (this.map !== null) {
                let area = this.map.querySelector('[data-name="' + location + '"]');
                if (area !== null) {
                    area.classList.add('active');
                }
            }
        }
    }
    _setCurrentMapLevel(lvl) {
        let last_active_map_level = this.map.querySelector('.plan__level--active');
        last_active_map_level.classList.remove('plan__level--active');

        let map_lvl = this.map.querySelector('[data-map-level="' + lvl + '"]');
        map_lvl.classList.add('plan__level--active');

        this.map_levels_switcher.querySelectorAll('[data-map-level-to]').forEach(item => {
            item.classList.remove('plan__map-single-level-button--active');
        });
        this.map_levels_switcher.querySelector('[data-map-level-to="' + lvl + '"]').classList.add('plan__map-single-level-button--active');

        this.current_map_level = lvl;
    }
    _setActiveShopsForCategory(shops_ids) {
        let shops = this.shop_list.querySelectorAll('[data-item-id]');
        // console.log(shops);
        shops.forEach(item => {
            item.style.display = 'none';
        });

        shops_ids.forEach(item => {
            this.shop_list.querySelector('[data-item-id="' + item + '"]').style.display = 'block';
        });
    }
    _clearActiveShops() {
        let shops = this.shop_list.querySelectorAll('[data-item-id]');
        shops.forEach(item => {
            item.style.display = 'block';
        });
    }
    _clearAllCategoriesActive() {
        let categories = this.categories.querySelectorAll('[data-item]');
        categories.forEach(item => {
            if (item.classList.contains('plan__categories-list-element--active')) {
                item.classList.remove('plan__categories-list-element--active');
            }
        });
    }

    _showShopsBySearchPhrase(string) {

        let shops = this.shop_list.querySelectorAll('[data-item-id]');
        shops.forEach(item => {
            let item_name = item.getAttribute('data-name').toLowerCase();
            if (item_name !== null) {
                if (item_name.includes(string.toLowerCase())) {
                    item.style.display = 'block';
                } else {
                    item.style.display = 'none';
                }
            }
        });
    }
    _showAllShopsOnList() {
        let shops = this.shop_list.querySelectorAll('[data-item-id]');
        shops.forEach(item => {
            item.style.display = 'block';
        });
    }
    _getLocalName(id) {
        let local_name;
        let local = this.shop_list.querySelector('[data-item-premise="' + id + '"]');
        if (local !== null) {
            local_name = local.getAttribute('data-name');
        }
        return local_name || '';
    }
    _getCoords(element) {
        let bounds = element.getBoundingClientRect(),
            body = document.body,
            doc_element = document.documentElement,
            scrollTop = this._getScroll().y,
            scrollLeft = this._getScroll().x,
            clientTop = doc_element.clientTop || body.clientTop || 0,
            clientLeft = doc_element.clientLeft || body.clientLeft || 0,
            top = bounds.top + scrollTop - clientTop - 10,
            left = bounds.left + scrollLeft - clientLeft;

        return { top: Math.round(top), left: Math.round(left) };
    }
    _getScroll() {
        return {
            x: window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            y: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        };
    }
    _createPopover(text) {
        let elementPattern = '<div class="popover">{{text}}</div>';
        elementPattern = elementPattern.replace('{{text}}', text);
        return elementPattern;
    }
    _createModal() {
        let elementPattern = '<div class="modal modal--shop-modal">' +
            '<a href="" class="modal__close" data-element="close">×</a>' +
            '<div class="modal__content"></div>' +
            '<div data-element="loader"><div class="loader"><div></div><div></div><div></div><div></div></div></div></div>';
        return elementPattern;
    }
    _createElementFromHTML(html) {
        let div = document.createElement('div');
        div.innerHTML = html.trim();
        return div.firstChild;
    }
    _clearMarkPaths() {
        if (this.map !== null) {
            let maps = this.map.querySelectorAll('[data-map-level]');
            if (maps !== null) {
                maps.forEach(map => {
                    let objects = map.querySelectorAll('[data-name]');
                    if (objects.length) {
                        objects.forEach(object => {
                            if (object.classList.contains('active')) {
                                object.classList.remove('active');
                            }
                        });
                    }
                });
            }
        }
    }
    _getShopData(shop_id) {
        let url = '/wp-json/rondo/v1/get_shop_data/' + shop_id;
        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
}
export default GalleryPlan;